import CreatePushModal from "./CreatePush.vue";

let modals = {
  CreatePushModal
}

export default modals;

export {
  CreatePushModal
}
