<template>
  <card class="main-content">
    <!-- content start -->
    <!-- search -->
    <div class="row">
      <div class="ml-auto py-2 pr-4">
        <input
          id="search-box"
          type="text"
          class="search-box"
          name="q"
          v-model="search"
        />
        <label for="search-box">
          <span class="fas fa-search search-icon"></span>
        </label>
      </div>
    </div>
    <!-- search -->
    <rich-menu-table
      :data="table.data"
      v-on:editRichMenu="editRichMenu"
      v-on:deleteRichMenu="deleteRichMenu"
    ></rich-menu-table>
    <pagination
      v-model="page"
      :per-page="rowPerPage"
      :records="richMenus.length"
      @paginate="callback"
      :options="{
        texts: {
          count: this.$t('__paginationText')
        }
      }"
    ></pagination>
    <!-- add button-->
    <div class="btn-follow">
      <a
        href="javascript:void(0)"
        style="color: #fff"
        @click="showCreateMenuModal()"
      >
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <!-- content end -->
    <create-edit-rich-menu-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      v-on:submitRichMenu="submitRichMenu"
      v-bind:segmentList="segmentList"
      v-bind:formInput="modalData"
      v-bind:menuList="table.data"
      ref="refCreateMenuModal"
    ></create-edit-rich-menu-modal>
  </card>
</template>
<script>
import { RichMenuTable } from "./Components/index";
import { CreateEditRichMenuModal } from "./Modals/index";
import { apiFunction } from "../../Axios/functions";

export default {
  components: {
    RichMenuTable,
    CreateEditRichMenuModal,
  },
  data() {
    return {
      adminId: "",
      jwtToken: "",
      accountId: "",
      search: "",
      segmentList: [],
      page: 1,
      rowPerPage: 20,
      table: {
        columns: [],
        data: [],
      },
      richMenus: [],
      modalData: {
        name: "",
        segmentId: "",
        layout: "A",
        areaOptions: {},
        imgWidth: 0,
        imgHeight: 0,
        imgType: "image/png",
        imageB64: "",
        tab_menu:[],
        start_time: "",
        end_time: "",
        use_datetime_trigger : false,
        open_menu: false
      },
      richmenudata: "",
      editAllUserCheck: false,
      sameDefaultCheck: false,
      actionNames: ["A", "B", "C", "D", "E", "F"],
    };
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val);
        let searchResult = [];
        $.each(this.richMenus, function (i, menu) {
          if (
            reg.test(menu.name) == true ||
            reg.test(menu.segmentName) == true
          ) {
            searchResult.push(menu);
          }
        });
        this.table.data = searchResult;
      },
    },
  },
  created() {
    this.$root.$refs.richmenulang = this;
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: "error"
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      // Get segment
      let formData = { adminId: this.adminId, accountId: this.accountId };
      apiFunction.getUserSegment(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: "error"
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          this.segmentList = data.data.Result.data;
          this.getRichMenu();
        }
      });
    },
    getRichMenu() {
      let formData = { adminId: this.adminId, accountId: this.accountId };
      apiFunction.getRichMenu(formData, this.jwtToken).then((data) => {
        this.richmenudata = data;
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        } else {
          this.richMenus.splice(0);
          console.log(data.data.Result.data)
          data.data.Result.data.forEach((menu) => {
            let segmentId =
              menu.user_segment.length > 0 ? menu.user_segment[0] : this.$t("__richAllUser");
            let segment = this.segmentList.find(
              (segment) => segment.id === segmentId
            );
            let segmentName = segment ? segment.name : this.$t("__richAllUser")
            this.richMenus.push({
              id: menu.id,
              name: menu.name,
              segmentId: segmentId,
              segmentName: segmentName,
              layout: menu.layout,
              areaOptions: menu.area_opt,
              image_width: menu.image_width,
              image_height: menu.image_height,
              image_type: "image/png",
              image_b64: "",
              chat_bar_text: menu.chat_bar_text ? menu.chat_bar_text : "",
              tab_menu: menu.tab_menu,
              start_time: menu.start_time,
              end_time: menu.end_time,
              use_datetime_trigger: menu.use_datetime_trigger,
              open_menu: menu.open_menu
            });
          });
          this.callback(this.page);
        }
      });
    },
    // Pagination
    callback(pageNum) {
      let arr = this.richMenus.slice(
        this.rowPerPage * (pageNum - 1),
        this.rowPerPage * pageNum
      );
      this.table.data = arr;
    },
    showCreateMenuModal() {
      var tempAreaOptions = {};
      for (var i = 0; i < this.actionNames.length; i++) {
        tempAreaOptions[this.actionNames[i]] = {
          type: "text",
          text: "",
          url: "",
        };
      }
      this.modalData = {
        name: "",
        segmentId: "",
        layout: "A",
        areaOptions: tempAreaOptions,
        imgWidth: 0,
        imgHeight: 0,
        imgType: "image/png",
        imageB64: "",
        chatBarText: "",
        tab_menu:[

        ],
        start_time: "",
        end_time: "",
        use_datetime_trigger : false,
        open_menu: false
      };
      this.$refs.refCreateMenuModal.editMode = false;
      $("#create-edit-rich-menu-modal").modal("show");
    },
    editRichMenu(richMenu) {
      var tempAreaOptions = {};
      for (const [key, action] of Object.entries(richMenu.areaOptions)) {
        tempAreaOptions[key] = {
          type: action.type,
          text: action.type === "text" ? action.data : "",
          url: action.type === "uri" ? action.data : "",
        };
      }
      this.modalData = {
        id: richMenu.id,
        name: richMenu.name,
        segmentId: richMenu.segmentId,
        layout: richMenu.layout,
        areaOptions: tempAreaOptions,
        imgWidth: richMenu.image_width,
        imgHeight: richMenu.image_height,
        imgType: richMenu.image_type,
        imageB64: richMenu.image_b64,
        chatBarText: richMenu.chat_bar_text ? richMenu.chat_bar_text : "",
        tab_menu: richMenu.tab_menu,
        start_time: richMenu.start_time,
        end_time: richMenu.end_time,
        use_datetime_trigger: richMenu.use_datetime_trigger,
        open_menu: richMenu.open_menu
      };
      this.$refs.refCreateMenuModal.editMode = true;
      $("#create-edit-rich-menu-modal").modal("show");
      if(richMenu.segmentName == this.$t("__richAllUser")){
        this.editAllUserCheck = true;
        this.sameDefaultCheck = true;
      }
    },
    deleteRichMenu(richMenu, index) {
      this.$fire({
        title: this.$t("__Areyousureyouwanttodeleteit"),
        text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("__YesIwanttodelete"),
        cancelButtonText: this.$t("__cancel"),
      }).then((result) => {
        if (result.value) {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            richmenuId: richMenu.id,
          };
          apiFunction.deleteRichMenu(formData, this.jwtToken).then((data) => {
            if (data && data.status == 200) {
              this.$fire({
                title: this.$t("__updatecompleted"),
                type: "success",
                timer: 1500,
              }).then((e) => {
                this.richMenus.splice(index, 1);
                this.callback(this.page);
              });
            } else {
              this.$fire({
                title: this.$t("__Addfailed"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            }
          });
          this.loadData();
        }
      });
    },
    submitRichMenu(formInput) {
      console.log(formInput)
      for (var key in formInput.areaOptions) {
        formInput.areaOptions[key] = {
          type: formInput.areaOptions[key].type,
          data:
            formInput.areaOptions[key].type == "text"
              ? formInput.areaOptions[key].text
              : formInput.areaOptions[key].url,
        };
      }
      var formData;
      formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        name: formInput.name,
        user_segment: [formInput.segmentId],
        layout: formInput.layout,
        area_opt: formInput.areaOptions,
        image_width: formInput.imgWidth,
        image_height: formInput.imgHeight,
        image_type: formInput.imgType,
        image_b64: formInput.imageB64,
        chat_bar_text: formInput.chatBarText,
        tab_menu: formInput.tab_menu,
        start_time: formInput.start_time,
        end_time: formInput.end_time,
        use_datetime_trigger: formInput.use_datetime_trigger,
        open_menu: formInput.open_menu
      };
      let dataCount = this.richmenudata.data.Result.data
      let defaultExsit = dataCount.find(o => o.user_segment[0] === "richAllUser");
      if(defaultExsit && this.sameDefaultCheck == false){
        this.editAllUserCheck = false;
      }
      // if (this.modalData.segmentId == "richAllUser" && defaultExsit && this.editAllUserCheck == false) {
      //   //預設menu只能有一個
      //   return this.$fire({
      //     title: this.$t("__Addfailed"),
      //     text: this.$t("__haveDefaultMenu"),
      //     type: "error",
      //   });
      // }else{
        if(formInput.id){
          let deleteFormData = {
              adminId: this.adminId,
              accountId: this.accountId,
              richmenuId: formInput.id,
          };
          apiFunction.saveRichMenu(formData, this.jwtToken).then((data) => { 
            if (data && data.status == 200) {
              this.$fire({
                title: this.$t("__updatecompleted"),
                type: "success",
                timer: 1500,
              }).then((e) => {
                apiFunction.deleteRichMenu(deleteFormData, this.jwtToken).then((data) => {
                  if (data && data.status == 200) {
                    this.getRichMenu();
                  } 
                  else{
                    this.$fire({
                      title: this.$t("__Addfailed"),
                      text: this.$t("__somethingwentwrongindeletemenu"),
                      type: "error",
                    });
                  }
                })
              })
            }else{
              this.$fire({
                title: this.$t("__Addfailed"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            };
          });
        }else{
          apiFunction.saveRichMenu(formData, this.jwtToken).then((data) => { 
            if (data && data.status == 200) {
              this.$fire({
                title: this.$t("__updatecompleted"),
                type: "success",
                timer: 1500,
              }).then((e) => {
                this.getRichMenu();
              });
            } else {
              this.$fire({
                title: this.$t("__Addfailed"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            };
          });
        };
      // };
      this.editAllUserCheck = false;
    },
  },
};
</script>
<style>
</style>
