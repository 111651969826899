<template>
  <!-- Create New Tag Modal Start -->
  <div
    class="modal fade bd-example-modal"
    id="create-tag-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold">{{ $t("__Addlabel") }}</p>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div>
              <span class="text-danger">*</span> {{ $t('__requiredItem') }}
            </div>
            <label class="pt-3 mb-2" data-translate="_selectColor.edit">
              <span class="text-danger">*</span> {{ $t("__Labelname") }}
            </label>
            <div class="row">
              <input
                type="text"
                class="form-control px-4 mx-3"
                :placeholder="$t('__Labelname')"
                v-model="tagName"
              />
            </div>
            <label class="pt-3 mb-0" data-translate="_selectColor.edit">
              <span class="text-danger">*</span> {{ $t("__Chooselabelcolor") }}
            </label>
            <div class="row">
              <div class="phone-screen mb-4">
                <div class="color-filters">
                  <ul>
                    <li v-for="(color, index) in colorOptions" :key="index">
                      <input
                        type="radio"
                        name="colorFilters"
                        v-model="colorFilter"
                        :id="color"
                        :value="color"
                      />
                      <label :for="color" :id="color" :class="color"></label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <label class="ml-auto" style="font-size: 0.8em">
                <span class="text-danger">※</span>
                {{ $t("__Thenewlabelmustbereusedafterthestoragenode") }}
              </label>
            </div>
            <div class="d-flex">
              <label class="pt-3 mb-2">Values</label>
              <button
                class="btn btn-primary btn-sm ml-auto my-auto"
                style="height: 32px"
                @click="addTagValue()"
              >
                {{ $t("__Add") }}
              </button>
            </div>
            <div>
              <ul class="list-group">
                <li
                  class="list-group-item p-1"
                  v-for="(value, index) in TagValues"
                  :key="index"
                >
                  <input type="text" v-model="TagValues[index]" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="create-story-tag"
            class="btn btn-secondary"
            style="width: 100%"
            @click="createTag()"
          >
            {{ $t("__Createnewlabel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Create New Tag Modal End -->
</template>

<script>
export default {
  name: "create-tag-modal",
  data() {
    return {
      colorFilter: null,
      tagName: "",
      TagValues: [],
      newTag: {},
      colorOptions: [
        "bg-danger",
        "red-filter",
        "pink-filter",
        "orange-filter",
        "bg-warning",
        "yellow-filter",
        "bg-success",
        "dark-green-filter",
        "green-filter",
        "bg-info",
        "teal-filter",
        "primary-filter",
        "blue-filter",
        "skyblue-filter",
        "darkpurple-filter",
        "purple-filter",
        "bg-secondary",
        "grey-filter",
      ],
    };
  },
  methods: {
    // Add new tag
    createTag() {
      if (this.colorFilter !== null && this.tagName !== "") {
        this.newTag = {
          name: this.tagName,
          color: this.colorFilter,
          values: this.TagValues,
        };

        this.colorFilter = null;
        this.tagName = "";
        this.TagValues = [];
        $("#create-tag-modal").modal("hide");
        this.$emit("newTag", this.newTag);
      } else {
        return this.$fire({
          title: this.$t("__Addfailed"),
          text: this.$t("__Pleaseenteranameandchooseacolor"),
          type: "error",
        });
      }
    },
    addTagValue() {
      this.TagValues.push("");
    },
  },
};
</script>
