<template>
    <card class="main-content">
        <!-- content place -->
        <meta-utils-table type="hover table-bordered" :adminId="adminId" :accountId="accountId" :allUtils="allUtils"
            :data="table.data" v-bind:currentNumber="allUtils.length"></meta-utils-table>
        <!-- content place -->
    </card>
</template>
<script>
import { MetaUtilsTable } from "./Components/index";
import { apiFunction } from "../MetaUtils/Axios/functions";

export default {
    components: {
        MetaUtilsTable,
    },
    data() {
        return {
            adminId: "",
            jwtToken: "",
            accountId: "",
            allUtils: [],
            table: {
                data: [],
            },
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        // Prepare page content
        async loadData() {
            // Getting required Id with route parameter
            if (localStorage._token) {
                let decrypt = this.$aes.decrypt(localStorage._token).split(",");
                this.adminId = decrypt[0];
                this.accountId = decrypt[1];
            }
            try {
                let user = await this.$Amplify.Auth.currentAuthenticatedUser();
                this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
                
                //Get the Utils of the account
                const formData = { "adminId": this.adminId, "accountId": this.accountId };
                await apiFunction.getUtils(formData, this.jwtToken).then((data) => {
                    if(data.status === 200){
                        const dataList = data.data.Result.data;
                        dataList.forEach(element => {
                            this.table.data.push(element)
                        });
                    }else{
                        this.$fire({
                            title: this.$t("__authExpired"),
                            text: this.$t("__pleaseLogin"),
                            type: "error",
                        }).then(() => {
                            this.$Amplify.Auth.signOut();
                            localStorage.clear();
                            this.$router.push("/login");
                        });
                    }
                });
                
            } catch (e) {
                this.$fire({
                    title: this.$t("__authExpired"),
                    text: this.$t("__pleaseLogin"),
                    type: "error",
                }).then(() => {
                    this.$Amplify.Auth.signOut();
                    localStorage.clear();
                    this.$router.push("/login");
                });
            }
        },
    },
};
</script>