<template>
  <card class="main-content">
    <!-- content place -->

    <!-- Api Target -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">
        <h5>{{ $t("__ApiLink") }}</h5>
      </div>
      <div class="col-9 my-2">
        <span>{{ apiLink }}</span>
      </div>
    </div>

    <!-- End Point -->
    <!-- <div class="row mt-4 my-2">
      <div class="col-3 my-2"><h5>{{ $t("__EndPoint") }}</h5></div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="endPoint-title">{{ endPoint }}</p>
        <span id="endPoint-edit" @click="editEndPoint()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="endPoint-input"
          v-model="endPoint"
          @keypress="confirmChangeEndPoint($event)"
        />
      </div>
    </div> -->

    <!-- Client Id -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2"><h5>{{ $t("__ClientId") }}</h5></div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="clientId-title">{{ clientId }}</p>
        <span id="clientId-edit" @click="editClientId()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="clientId-input"
          v-model="clientId"
          @keypress="confirmChangeClientId($event)"
        />
      </div>
    </div>

    <!-- Key -->
    <div class="row mt-4 my-2 none">
      <div class="col-3 my-2"><h5>Key</h5></div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="key-title">{{ key }}</p>
        <span id="key-edit" @click="editKey()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="key-input"
          v-model="key"
          @keypress="confirmChangeKey($event)"
        />
      </div>
    </div>

    <!-- Parameters -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2"><h5>payloads</h5></div>
      <div class="col-9 my-2">


        <div v-for="(payload,pindex) in payloads">

          <div class="shadow p-3 mb-5 bg-white rounded">
            <div class="badge badge-primary text-wrap" style="width: 2rem;">
              NO {{pindex+1}}
            </div>
            <button type="button" @click="deletePayload(pindex)" class="btn btn-outline-danger float-right" style="padding: 2px;padding-right: 8px;padding-left: 8px;">Delete Payload</button>
            <!-- EndPoint -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">{{ $t("__EndPoint") }}</div>
              <div class="row col-9 my-2">
                <p class="mb-2 col-9" :id="'endPoint'+pindex+'title'">{{ payload.endpoint }}</p>
                <span :id="'endPoint-'+pindex+'-edit'" @click="editUI('endPoint-'+pindex)">
                  <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                </span>
                <input
                  type="text"
                  class="form-control mb-2 col-9 element-hide"
                  :id="'endPoint-'+pindex+'-input'"
                  v-model="payload.endpoint"
                  @keypress="confirmUIChangeKey($event,'endPoint-'+pindex)"
                />
              </div>
            </div>
            <!-- App ID -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">App ID</div>
              <div class="row col-9 my-2">
                <p class="mb-2 col-9" :id="'appId-'+pindex+'-title'">{{ payload.app_id }}</p>
                <span :id="'appId-'+pindex+'-edit'" @click="editUI('appId-'+pindex)">
                  <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                </span>
                <input
                  type="text"
                  class="form-control mb-2 col-9 element-hide"
                  :id="'appId-'+pindex+'-input'"
                  v-model="payload.app_id"
                  @keypress="confirmUIChangeKey($event,'appId-'+pindex)"
                />
              </div>
            </div>
            <!-- Token -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">Token</div>
              <div class="row col-9 my-2">
                <p class="mb-2 col-9" :id="'token-'+pindex+'-title'">{{ payload.token }}</p>
                <span :id="'token-'+pindex+'-edit'" @click="editUI('token-'+pindex)">
                  <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                </span>
                <input
                  type="text"
                  class="form-control mb-2 col-9 element-hide"
                  :id="'token-'+pindex+'-input'"
                  v-model="payload.token"
                  @keypress="confirmUIChangeKey($event,'token-'+pindex)"
                />
              </div>
            </div>
            <!-- Behavior -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">Behavior</div>
              <select class="col-4 my-2" v-model="payload.behavior">
                <!-- 初期値 -->
                <!-- <option value disabled selected>
                  {{ $t("__UserVariableSelectOptional") }}
                </option> -->
                <option
                  v-for="(value, index) in allBehavior"
                  :value="value"
                  :key="'behavior-' + index"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <!-- UpdateKey -->
            <div class="row mt-4 my-2">
              <div class="col-3 my-2">Update Key</div>
              <div class="row col-9 my-2">
                <p class="mb-2 col-9" :id="'updatekey-'+pindex+'-title'">{{ payload.updatekey }}</p>
                <span :id="'updatekey-'+pindex+'-edit'" @click="editUI('updatekey-'+pindex)">
                  <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                </span>
                <input
                  type="text"
                  class="form-control mb-2 col-9 element-hide"
                  :id="'updatekey-'+pindex+'-input'"
                  v-model="payload.updatekey"
                  @keypress="confirmUIChangeKey($event,'updatekey-'+pindex)"
                />
              </div>
            </div>

            <div class="row mb-2 align-items-center">

              <div class="row col-6 my-2">
                <p class="col-9 my-2"><b>Parameter</b></p>
              </div>
              <p class="col-4 my-2"><b>Variable</b></p>
              <a
                href="javascript:void(0)"
                class="text-info m-1"
                @click="addParameter(pindex)"
                v-if="params.length == 0"
              >
                <i class="fas fa-plus text-info"></i>
              </a>
            </div>
            <div class="row mb-2" v-for="(param, index) in payload.paramsObjectlize" :key="index">
              <div class="row col-6 my-2">
                <p class="col-9" :id="'param-title-' +param.id">
                  {{ param.externalAttribute }}
                </p>
                <span :id="'param-edit-' + param.id" @click="editParameter(param.id)">
                  <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                </span>
                <input
                  type="text"
                  class="form-control col-9 element-hide"
                  :id="'param-input-' + param.id"
                  v-model="param.externalAttribute"
                  @keypress="confirmChangeParameter($event, param.id)"
                />
              </div>
              <select class="col-4 my-2" v-model="param.userVariable">
                <option value disabled selected>
                  {{ $t("__UserVariableSelectOptional") }}
                </option>
                <option
                  v-for="(value, index) in allVariables"
                  :value="value"
                  :key="'variable-' + index"
                >
                  {{ value }}
                </option>
              </select>
              <div class="ml-1">
                <a
                  href="javascript:void(0)"
                  class="text-danger m-1"
                  @click="deleteParameter(pindex,index)"
                  v-if="payload.paramsObjectlize.length > 0"
                >
                  <i class="fas fa-minus text-danger"></i>
                </a>
              </div>
              <div class="ml-1">
                <a
                  href="javascript:void(0)"
                  class="text-info m-1"
                  @click="addParameter(pindex)"
                  v-if="index == payload.paramsObjectlize.length - 1"
                >
                  <i class="fas fa-plus text-info"></i>
                </a>
              </div>
            </div>

          </div>
        </div>

        <div
          class="row pt-4 add-btn-input cursor-pointer"
          @click="addPayload()">
          <a href="javascript:void(0)" class="mx-auto my-3">
            <i class="fas fa-plus"></i>
          </a>
        </div>
      </div>
      
    </div>

    
    <!-- Save -->
    <button
      class="btn btn-outline-success pull-right"
      @click="updateAccountExternalApiConfig()"
      data-translate
    >
      Update
    </button>
    <!-- content place -->
  </card>
</template>
<script>
import { apiFunction } from "../../Axios/functions";

export default {
  data() {
    return {
      adminId: "",
      jwtClientId: "",
      accountId: "",
      allVariables: [],
      allBehavior: ["select", "update", "insert"],  // TODO とりあえずハードコーディング
      apiLink: "CROS",
      // endPoint: "",  // del hashimoto
      clientId: "",
      key: "999",
      params: [],
      payloads:[],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      this.getAllVariables();
      this.getAccountSetting();
    },
    getAccountSetting() {
      let formData = {
        adminId: this.adminId,
      };
      apiFunction.getAccount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          let accounts = data.data.Result.accounts;
          accounts.forEach((account) => {
            if (account.accountId == this.accountId) {

              if(account.setting.cros == null) return;
              if(account.setting.cros.payloads == null) return;
              
              let cros = account.setting.cros;

              // del hashimoto
              // this.endPoint = cros["endpoint"] != null? cros["endpoint"]: "";
              this.clientId = cros["clientid"] != null? cros["clientid"]:"";
              //this.key = cros["key"];  //todo delete pending
              //this.params.splice(0);   //todo delete pendinge
              this.payloads = cros.payloads;
              this.objectlizePayloads();
            }
          });
        }
      });
    },
    objectlizePayloads(){
      this.payloads.forEach((payload,pindex)=>{

        let keys = Object.keys(payload.params);
        payload.paramsObjectlize = [];
        for(let i=0;i<keys.length;i++){
          let key = keys[i];
          let value = payload.params[key]
          payload.paramsObjectlize.push({
            id:value+'-'+pindex+'-'+i,
            externalAttribute:key,
            userVariable:value
          });
        }
      });
    },
    getAllVariables() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      };
      // Get variables
      apiFunction.getVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        } else {
          this.allVariables = data.data.Result.data;
          this.allVariables.push("_USERID");  //環境變數，會讀取chatannie在資料庫的userid
        }
      });
    },
    addParameter(pindex) {
      if(pindex > this.payloads.length-1 )return;

      let payload = this.payloads[pindex];
      payload.paramsObjectlize.push({
        id: 'tmp-'+pindex+'-'+payload.paramsObjectlize.length,
        externalAttribute: '',
        userVariable:''
      });

      this.$forceUpdate();
    },
    deleteParameter(pindex,index) {
      if(pindex > this.payloads.length-1 )return;
      let payload = this.payloads[pindex];

      if(index > payload.paramsObjectlize.length-1) return;

      payload.paramsObjectlize.splice(index, 1);
      this.$forceUpdate();
    },
    deletePayload(pindex){
      if(pindex > this.payloads.length-1 ) return;
      this.payloads.splice(pindex, 1);
    },
    updateAccountExternalApiConfig() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        key:999,
        setting: {},
      };
      
      this.payloads.forEach(payload=>{
        payload.params = {}
        payload.paramsObjectlize.forEach(para=>{
          payload.params[para.externalAttribute] = para.userVariable;
        });
        delete payload.paramsObjectlize; 
      });

      formData.setting["cros"] = {
        // del hashimoto
        // endPoint: this.endPoint,
        clientId: this.clientId,
        key: this.key,
        payloads: this.payloads,
      };
      
      apiFunction
        .updateAccountExternalApiConfig(formData, this.jwtToken)
        .then((data) => {
          if (data && data.status == 200) {
            this.$fire({
              title: this.$t("__updatecompleted"),
              text: this.$t("__updatecompleted"),
              type: "success",
              timer: 1500,
            });

            this.objectlizePayloads();
          } else {
            this.$fire({
              title: this.$t("__Settingsnotyetsaved"),
              text: this.$t("__oopsSomethingwentwrong"),
              type: "error",
            });
          }
        });
    },
    editEndPoint() {
      $("#endPoint-title").hide();
      $("#endPoint-input").show();
      $("#endPoint-edit").hide();
    },
    confirmChangeEndPoint($event) {
      if ($event.which == 13) {
        $("#endPoint-title").show();
        $("#endPoint-input").hide();
        $("#endPoint-edit").show();
        return false;
      }
    },
    editClientId() {
      $("#clientId-title").hide();
      $("#clientId-input").show();
      $("#clientId-edit").hide();
    },
    confirmChangeClientId($event) {
      if ($event.which == 13) {
        $("#clientId-title").show();
        $("#clientId-input").hide();
        $("#clientId-edit").show();
        return false;
      }
    },
    editKey() {
      $("#key-title").hide();
      $("#key-input").show();
      $("#key-edit").hide();
    },
    editUI(key){
      $(`#${key}-title`).hide();
      $(`#${key}-input`).show();
      $(`#${key}-edit`).hide();
    },
    confirmUIChangeKey($event,key){
      if ($event.which == 13) {
        $(`#${key}-title`).show();
        $(`#${key}-input`).hide();
        $(`#${key}-edit`).show();
        return false;
      }
    },
    confirmChangeKey($event) {
      if ($event.which == 13) {
        $("#key-title").show();
        $("#key-input").hide();
        $("#key-edit").show();
        return false;
      }
    },
    editParameter(index) {
      $("#param-title-" + index).hide();
      $("#param-input-" + index).show();
      $("#param-edit-" + index).hide();
    },
    confirmChangeParameter($event, index) {
      if ($event.which == 13) {
        $("#param-title-" + index).show();
        $("#param-input-" + index).hide();
        $("#param-edit-" + index).show();

        this.$forceUpdate();
        return false;
      }
    },
    addPayload(){
      this.payloads.push({
        app_id:'',
        params:'',
        paramsObjectlize:[],
        token:'',
      });
    }
  },
};
</script>