<template>
  <!-- segment create modal start -->
  <div
    class="modal fade"
    id="segment"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <slot name="header">{{modalHeader}}</slot>
        </div>
        <div class="modal-body">
          <slot name="content">{{modalContent}}</slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">{{modalFooter}}</slot>
        </div>
      </div>
    </div>
  </div>
  <!-- segment modal end -->
</template>

<script>
export default {
  name: "modal",
  props: {
    modalHeader: { type: String, default: "" },
    modalContent: { type: String, default: "" },
    modalFooter: { type: String, default: "" }
  }
};
</script>
<style>
</style>