import axios from 'axios';

export const apiFunction = {
  // Get broadcast plan in pusg message page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getBroadcastPlan: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getBroadcastPlan",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get sent broadcast history from broadcast plan
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getBroadcastHistory: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getBroadcastHistory",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Delete broadcast plan in push message page
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "broadcastId": "string"
  // **
  // ***
  deleteBroadcast: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteBroadcast",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get all scenario for choosing broadcast item
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getScenario: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getScenario",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get user segment for broadcast target
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getUserSegment: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUserSegment",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get users from chat history for broadcast target
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getChatHistory: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getChatHistory",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get userlist
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getUserList: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUsersList",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Add broadcast plan
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "broadcastId": "string",
  // "broadcastName": "string",
  // "broadcastScenarioName": "string",
  // "broadcastScenarioId": "string",
  // "broadcastUsers": [
  //     {
  //         "userId": "string",
  //         "userName": "string",
  //         "userPicture": "string",
  //         "accountType": "string",
  //         "accountStatus": "string",
  //         "followTime": "string",
  //         "createTime": "string"
  //     }
  // ],
  // "broadcastTime": "2020-05-18 23:58:19",
  // "broadcastStatus": "pending",
  // "segmentName": "list"
  // **
  // ***
  scheduleBroadcast: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "scheduleBroadcast",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get usercount
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getUserCount: function (formData, jwtToken) {
      return axios({
          method: "POST",
          url: process.env.VUE_APP_API_ENDPOINT + "getUserCount",
          data: formData,
          headers: {
              Authorization: `Bearer ${jwtToken}`
          },
      }).then(res => {
          return res;
      }).catch(e => {
          console.log(e);
          return { error: e.response ? e.response : "null" }
      });
  },

};
