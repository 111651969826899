<template>
  <card class="main-content">
    <div id="content-wrapper">
      <div class="border border-success rounded px-4 py-4 mb-3">
        <h5>
          <u>
            <a href="#/story" data-translate="_node.top">{{ $t("__node") }}</a>
          </u>
        </h5>
        <span data-translate="_currentNode.top">{{
          $t("__Numberofexistingnodes")
        }}</span>
        <span>: {{ currentNodes }}</span>
      </div>
      <div class="border border-success rounded px-4 py-4 mb-3">
        <h5>
          <u>
            <a v-if="isNameFollower == true" href="#/segment/userList" data-translate="_follower.top">{{
              $t("__Followers")
            }}</a>
            <a v-if="isNameFollower == false" href="#/segment/userList" data-translate="_follower.top">{{
              $t("__Triggerers")
            }}</a>
          </u>
        </h5>
        <span data-translate>{{ $t("__Trackusersnow") }}</span>
        <span>: {{ currentFollowers }}</span>
      </div>
    </div>
  </card>
</template>
<script>
import { apiFunction } from "./Axios/functions";

export default {
  components: {},
  data() {
    return {
      adminId: "",
      jwtToken: "",
      accountId: "",
      currentNodes: 0,
      currentFollowers: 0,
      isNameFollower:true,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;

        let accountType = user.storage.accountType.toLowerCase();
        if(accountType == 'ig'){
          this.isNameFollower = false;
        }
      } catch (e) {
        this.$router.push("/login");
      }
      let formData = { adminId: this.adminId, accountId: this.accountId };
      // Count nodes;
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          let currentNodes = 0;
          $.each(data.data.Result.data, function (i, scenario) {
            currentNodes = currentNodes + scenario.nodes.length;
          });
          this.currentNodes = currentNodes;
        }
      });
      
      // Count followers;
      apiFunction.getUserCount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          // this.$fire({
          //   title: this.$t("__authExpired"),
          //   text: this.$t("__pleaseLogin"),
          //   type: "error",
          // }).then(() => {
          //   this.$Amplify.Auth.signOut();
          //   localStorage.clear();
          //   this.$router.push("/login");
          // });
          this.currentFollowers = 0;
        } else {
          this.currentFollowers = data.data.Result.data;
        }
      });
    },
  },
};
</script>
<style>
</style>
