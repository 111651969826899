<template>
  <!-- Create New Variable Modal Start -->
  <div
    class="modal fade bd-example-modal"
    id="create-variable-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold">{{ $t("__AddVariable") }}</p>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <label class="pt-3 mb-2" data-translate="_selectColor.edit">
              {{ $t("__Variablename") }}</label
            >
            <div class="row">
              <input
                type="text"
                class="form-control px-4 mx-3"
                v-model="newVariable"
                :placeholder="$t('__Variablename')"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            style="width: 100%"
            @click="createVariable()"
          >
            {{ $t("__Createnewvariable") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Create New Variable Modal End -->
</template>

<script>
export default {
  name: "create-variable-modal",
  data() {
    return {
      newVariable: "",
    };
  },
  methods: {
    // Add new variable
    createVariable() {
      $("#create-variable-modal").modal("hide");
      this.$emit("newVariable", this.newVariable);
      this.newVariable = "";
    }
  },
};
</script>
