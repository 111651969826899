export default {
  install(Vue, options) {
    Vue.prototype.$dateTime = {
      getDateTime: function () {
        const today = new Date();
        const date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date + " " + time;
        return dateTime;
      },
      getCurrentTime: function () {
        const today = new Date();
        const date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date + " " + time;
        return this.toIsoFormat(dateTime);
      },

      toIsoFormat: function (datetime) {
        return new Date(datetime).toISOString();
      },

      toFrontEndTimeFormat: function (datetime) {
        var date = new Date(datetime + ' UTC');
        var year = date.getFullYear();
        var month =
          date.getMonth() + 1 < 10 ?
          "0" + (date.getMonth() + 1) :
          date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var hours =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var minutes =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var seconds =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

        return (
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hours +
          ":" +
          minutes
        );
      },

      toBackendTimeFormat(datetime) {
        return new Date(datetime).toISOString().replace(/T/, ' ').replace(/\..+/, '');

      },
    }
  }
}
