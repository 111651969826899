<template>
  <!--Tags Choose modal start -->
  <div
    class="modal fade"
    id="tag"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    ref="existTagModalRef"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p
            class="modal-title text-bold"
            id="exampleModalCenterTitle"
            data-translate="_tag.edit"
          >
            {{ $t("__label") }}
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <!-- <div class="row">
              <input type="text" name class="form-control" placeholder="検索" />
            </div>
            <div class="row border my-1 py-1">
              <div class="col-12" v-for="(tag, index) in tags" :key="index">
                <button
                  class="btn btn-sm btn-primary exist-story-tag"
                  :class="tag.color"
                  style="width: 100%; height:30px; margin: 0.125rem 0;"
                  data-dismiss="modal"
                  @click="addExistTag(tag)"
                >{{tag.name}}</button>
              </div>
            </div>-->
            <div>
              <!-- search -->
              <div class="row">
                <div class="ml-auto py-2 pr-0">
                  <input
                    id="segment-search-tag"
                    type="text"
                    class="search-box"
                    name="q"
                    v-model="searchTag"
                  />
                  <label for="segment-search-tag">
                    <span class="fas fa-search search-icon"></span>
                  </label>
                  <button
                    class="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#create-tag-modal"
                    data-translate="_createNewTag.edit"
                  >
                    {{ $t("__Addlabel") }}
                  </button>
                </div>
              </div>
              <!-- search -->
              <div
                class="row border mb-3 py-2 px-3"
                style="overflow-y: scroll; max-height: 15rem"
              >
                <div
                  class="input-group mb-1"
                  v-for="(tag, index) in tags"
                  :key="index"
                >
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input
                        type="checkbox"
                        :value="tag"
                        :id="'tag' + index"
                        v-model="checkedTags"
                      />
                    </div>
                  </div>
                  <div class="py-2 border rounded col">
                    <a
                      href="javascript:void(0)"
                      class="tag"
                      :class="tag.color"
                      >{{ tag.name }}</a
                    >
                  </div>
                </div>
                <p class="m-auto py-4" v-if="tags.length == 0">
                  {{ $t("__Nolabelsavailable") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            style="width: 100%"
            data-dismiss="modal"
            @click="addExistTag()"
          >
            {{ $t("__determine") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Tags Choose Modal End -->
</template>

<script>
export default {
  name: "exist-tag-modal",
  props: {
    allTags: Array,
  },
  data() {
    return {
      tags: [],
      checkedTags: [],
      searchTag: "",
    };
  },
  mounted: function() {
    $(this.$refs.existTagModalRef).on("hidden.bs.modal", this.resetDataState);
  },
  watch: {
    searchTag: {
      handler: function (val, oldval) {
        let reg = new RegExp(val);
        let searchResult = [];

        $.each(this.allTags, function (i, tag) {
          if (reg.test(tag.name) == true) {
            searchResult.push(tag);
          }
        });
        this.tags = searchResult;
      },
    },
    allTags: {
      handler: function () {
        this.tags = this.allTags;
      },
    },
  },
  methods: {
    addExistTag() {
      if (this.checkedTags.length > 0) {
        this.$emit("addTag", this.checkedTags);
      }
    },
    resetDataState() {
      this.checkedTags = [];
      this.searchTag = "";
    }
  },
};
</script>
