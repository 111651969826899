<template>
  <card class="main-content">
    <div class="row">
      <!-- TODO 使用手冊下載 -->
    </div>
    <account-card
      :data="account.data"
      :adminId="adminId"
      :jwtToken="jwtToken"
    ></account-card>
    <!-- add button-->
    <div class="btn-follow">
      <a href="javascript:void(0)" style="color: #fff" @click="createAccount()">
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <create-account
      v-if="componentKey"
      :key="componentKey"
      :adminId="createAccountData.adminId"
      :jwtToken="createAccountData.jwtToken"
      :accountId="createAccountData.accountId"
      v-bind:allAccounts="account.data"
      :webhookUrl="createAccountData.webhookUrl"
    ></create-account>
  </card>
</template>

<script>
import { CreateAccount } from "./Modals/index";
import { AccountCard } from "./Components";
import { apiFunction } from "./Axios/functions";
import md5 from 'js-md5';
import Vue from "vue";

const cardData = [];

export default {
  components: {
    CreateAccount,
    AccountCard,
  },
  // TODO add admin Id after AWS cognito
  data() {
    return {
      res: [],
      adminId: "",
      jwtToken: "",
      account: {
        data: [...cardData],
      },
      createAccountData: {
        adminId: "",
        jwtToken: "",
        accountId: "",
        webhookUrl: "",
      },
      componentKey: 0,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await this.adminCheck();
      let formData = { adminId: this.adminId };

      apiFunction.getAccount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          this.account.data = data.data.Result.accounts;
        }
      });
    },
    // If admin is missing, return to admin login page
    async adminCheck() {
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.adminId = user.username;
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
    },
    // Render popup modal when click
    forceRerender() {
      this.componentKey += 1;
    },
    // Make account id
    // Example: p2ujl1brzr-x0n8-v2z5-6p7i-x7nz6ba96ba71
    generateNewId(maxAccountId) {
      let id =
        Math.random().toString(36).slice(2) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        md5(this.$dateTime.getDateTime()).substr(2, 8) +
        (maxAccountId + 1).toString();

      return id;
    },
    // Init new account
    createAccountInit(data) {
      let maxAccountId = data.length;
      this.createAccountData.adminId = this.adminId;
      this.createAccountData.jwtToken = this.jwtToken;
      this.createAccountData.accountId = this.generateNewId(maxAccountId);
      this.createAccountData.webhookUrl =
        process.env.VUE_APP_API_ENDPOINT +
        "api/LineMessage/send?adminid=" +
        this.createAccountData.adminId +
        "&accountid=" +
        this.createAccountData.accountId;
    },
    // Open create account modal in Modals/CreateAccount.vue
    createAccount() {
      this.forceRerender();
      this.createAccountInit(this.account.data);
    },
  },
};
</script>
