import axios from 'axios';

export const apiFunction = {
  // Get account
  // ***required fields
  // **
  // "adminid": "string"
  // **
  // ***
  getAccount: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getAccount",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  // Get variables
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getVariable: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getVariable",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  // Update Account External Api Config
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "setting": "object"
  // **
  // ***
  updateAccountExternalApiConfig: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "updateAccountExternalApiConfig",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  // Get Tag list
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getTags: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getTag",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Save tag
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "tag": {
  //    "name": "string",
  //    "color": "string",
  //    "values": ["string"],
  // }
  // **
  // ***
  saveTag: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveTag",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },
  // Save tag
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "tag": {
  //    "name": "string",
  //    "color": "string",
  //    "values": ["string"],
  // }
  // **
  // ***
  saveTagUser: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveTagUser",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },
  // Dalete tag
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "tag": {
  //    "name": "string",
  //    "color": "string",
  // }
  // **
  // ***
  deleteTag: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteTag",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },
  // Dalete tag User
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "tag": {
  //    "name": "string",
  //    "color": "string",
  // }
  // **
  // ***
  deleteTagUser: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteTagUser",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },
  // Get user 
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "userId":"string"
  // **
  // ***
  getUser: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUser",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  }, 
  // block user 
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "userId":"string"
  // **
  // ***
  blockUser: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "blockUser",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },  
  // unblock user 
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "userId":"string"
  // **
  // ***
  unblockUser: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "unblockUser",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },    
  // Get user segment for broadcast target
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getUserSegment: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUserSegment",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Get Rich Menu list
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getRichMenu: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getMenu",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Delete Rich Menu
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "richmenuId": "string"
  // ***
  deleteRichMenu: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteMenu",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Save rich menu
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "name": "string"
  // "user_segment": "string"
  // "layout": "string"
  // "area_opt": "object"
  // "image_width": "number"
  // "image_height": "number"
  // "image_type": "string"
  // "image_b64": "string"
  // **
  // ***
  saveRichMenu: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveMenu",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Get user icon
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getIconUrl: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getIconUrl",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Save user icon
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "accountIconUrl": [{
  //    "name": "string",
  //    "url": "string",
  // }]
  // **
  // ***
  saveIconUrl: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveIconUrl",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },

  // Delete user icon
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "accountIconUrl": [{
  //    "id": "string",
  // }]
  // **
  // ***
  deleteIconUrl: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteIconUrl",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return { error: e.response ? e.response : "null" }
    });
  },
};
