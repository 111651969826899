import CreateTagModal from "./CreateTag.vue";


let modals = {
    CreateTagModal
}

export default modals;

export {
    CreateTagModal
}
